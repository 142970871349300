@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family-ubuntu);
  font-weight: var(--font-weight-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  /* fonts */
  --font-family-ubuntu: Ubuntu, sans-serif;
  --font-weight-normal: 400;
  --font-weight-bold: 700; 
  /* font sizes */
  --body-1-size: 16px;
  --h2-size: 20px;
  --font-size-base-7: 15.7px;
  --font-size-3xs: 10px;
  --body-10-size: 14px;
  --h1-size: 24px;
  --font-size-lgi: 19px;
  --h11-size: 48px;
  --font-size-19xl: 38px;
  --font-size-10xl: 29px;
  --font-size-base-8: 16.8px;
  --font-size-smi: 13px;
  --font-size-smi-6: 12.6px;
  --font-size-base-4: 15.4px;
  --font-size-xl-7: 20.7px;
  --font-size-3xs-9: 9.9px;
  --h6-size: 35px;

  /* Colors */
  --gris-20: #f5f5f5;
  --gris-principal: #413e43;
  --color-gainsboro-100: #d9d9d9;
  --color-gainsboro: rgba(217, 217, 217, 0);
  --blanco: #fff;
  --color-black: #000;
  --osp: #d31d16;
  --gris-70: #c7c7c7;
  --gris-80: #6f7b7e;
  --color-gray: #040506;
  --color-tomato: #fa423d;

  /* Gaps */
  --gap-38xl: 57px;
  --gap-7xl: 26px;
  --gap-lgi: 19px;
  --gap-5xs-1: 7.1px;
  --gap-13xl-8: 32.8px;
  --gap-base: 16px;
  --gap-18xl-6: 37.6px;
  --gap-21xl-9: 40.9px;
  --gap-xl: 20px;
  --gap-9xl-2: 28.2px;
  --gap-21xl-8: 40.8px;
  --gap-17xl: 50px;
  --gap-lg: 18px;
  --gap-26xl-5: 45.5px;
  --gap-4xl: 23px;
  --gap-8xl-1: 27.1px;
  --gap-3xs-5: 9.5px;
  --gap-6xl: 25px;
  --gap-smi: 13px;
  --gap-3xs-8: 9.8px;
  --gap-mini: 15px;
  --gap-sm-3: 13.3px;
  --gap-23xl: 42px;
  --gap-mid: 17px;
  --gap-8xs-3: 4.3px;
  --gap-11xl: 30px;
  --gap-15xl: 34px;
  --gap-10xl: 29px;
  --gap-5xl: 24px;
  --gap-44xl: 63px;
  --gap-12xl: 31px;

  /* Paddings */
  --padding-9xl: 28px;
  --padding-50xl: 69px;
  --padding-15xl: 34px;
  --padding-71xl-5: 90.5px;
  --padding-9xs: 4px;
  --padding-62xl-5: 81.5px;
  --padding-56xl-5: 75.5px;
  --padding-13xl: 32px;
  --padding-31xl: 50px;
  --padding-xl: 20px;
  --padding-27xl: 46px;
  --padding-11xl: 30px;
  --padding-3xs: 10px;
  --padding-smi-7: 12.7px;
  --padding-20xl: 39px;
  --padding-10xs: 3px;
  --padding-17xl-1: 36.1px;
  --padding-17xl: 36px;
  --padding-6xl: 25px;
  --padding-6xs: 7px;
  --padding-7xl-4: 26.4px;
  --padding-sm: 14px;
  --padding-smi: 13px;
  --padding-mid: 17px;
  --padding-lg: 18px;
  --padding-2xl: 21px;
  --padding-21xl: 40px;
  --padding-7xl: 26px;
  --padding-54xl: 73px;
  --padding-5xs-7: 7.7px;
  --padding-4xs: 9px;
  --padding-4xs-4: 8.4px;
  --padding-7xs: 6px;
  --padding-5xs: 8px;
  --padding-xs: 12px;
  --padding-mini-5: 14.5px;
  --padding-216xl: 235px;
  --padding-39xl: 58px;
  --padding-98xl: 117px;
  --padding-2xs: 11px;
  --padding-8xs: 5px;
  --padding-10xs-9: 2.9px;
  --padding-3xl: 22px;
  --padding-760xl: 779px;
  --padding-310xl: 329px;
  --padding-195xl: 214px;
  --padding-11xs: 2px;
  --padding-mini: 15px;
  --padding-base: 16px;
  --padding-44xl: 63px;
  --padding-42xl: 61px;
  --padding-8xs-2: 4.2px;
  --padding-8xs-3: 4.3px;
  --padding-11xs-1: 1.1px;
  --padding-12xs: 1px;
  --padding-357xl: 376px;
  --padding-348xl: 367px;
  --padding-72xl: 91px;
  --padding-75xl: 94px;
  --padding-164xl: 183px;
  --padding-169xl: 188px;
  --padding-29xl: 48px;
  --padding-3xs-5: 9.5px;
  --padding-2xs-5: 10.5px;
  --padding-2xs-6: 10.6px;
  --padding-51xl: 70px;
  --padding-10xl: 29px;
  --padding-62xl: 81px;

  /* Border radiuses */
  --br-5xs-8: 7.8px;
  --br-lgi-4: 19.4px;
  --br-2xs-6: 10.6px;
  --br-xl: 20px;
  --br-8xs-7: 4.7px;
  --br-6xs: 7px;
  --br-10xs-5: 2.5px;
  --br-3xs: 10px;
}


@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}


@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
